var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.formData.kindConfigurations
    ? _c(
        "div",
        [
          _c("b-dd-divider", { staticClass: "mb-4" }),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { sm: "12" } }, [
                _c("h5", { staticClass: "text-uppercase font-weight-bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("planModal.sections.planInstallments.title")
                      ) +
                      " "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t(
                          "planModal.kindConfigurations.FINE.installments"
                        ),
                        "label-for": "fine-installments-input",
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "fine-installments-input",
                          disabled: _vm.readonly,
                          options: _vm.inputOptions["installments"],
                          state: _vm.checkField("kindConfigurations"),
                          value:
                            _vm.formData.kindConfigurations.FINE.installments,
                          "data-test": "fine-installments-input",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.kindConfig(
                              "FINE",
                              "installments",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t(
                          "planModal.kindConfigurations.MAINTENANCE.installments"
                        ),
                        "label-for": "maintenance-installments-input",
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "maintenance-installments-input",
                          disabled: _vm.readonly,
                          options: _vm.inputOptions["installments"],
                          state: _vm.checkField("kindConfigurations"),
                          value:
                            _vm.formData.kindConfigurations.MAINTENANCE
                              .installments,
                          "data-test": "maintenance-installments-input",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.kindConfig(
                              "MAINTENANCE",
                              "installments",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t(
                          "planModal.kindConfigurations.RECOVER.installments"
                        ),
                        "label-for": "recover-installments-input",
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "recover-installments-input",
                          disabled: _vm.readonly,
                          options: _vm.inputOptions["installments"],
                          state: _vm.checkField("kindConfigurations"),
                          value:
                            _vm.formData.kindConfigurations.RECOVER
                              .installments,
                          "data-test": "recover-installments-input",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.kindConfig(
                              "RECOVER",
                              "installments",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t(
                          "planModal.kindConfigurations.THEFT.installments"
                        ),
                        "label-for": "theft-installments-input",
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "theft-installments-input",
                          disabled: _vm.readonly,
                          options: _vm.inputOptions["installments"],
                          state: _vm.checkField("kindConfigurations"),
                          value:
                            _vm.formData.kindConfigurations.THEFT.installments,
                          "data-test": "theft-installments-input",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.kindConfig(
                              "THEFT",
                              "installments",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t(
                          "planModal.kindConfigurations.SUBSCRIPTION_TERMINATION.installments"
                        ),
                        "label-for":
                          "subscription-termination-installments-input",
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "subscription-termination-installments-input",
                          disabled: _vm.readonly,
                          options: _vm.inputOptions["installments"],
                          state: _vm.checkField("kindConfigurations"),
                          value:
                            _vm.formData.kindConfigurations
                              .SUBSCRIPTION_TERMINATION.installments,
                          "data-test":
                            "subscription-termination-installments-input",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.kindConfig(
                              "SUBSCRIPTION_TERMINATION",
                              "installments",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t(
                          "planModal.kindConfigurations.OTHERS.installments"
                        ),
                        "label-for": "others-installments-input",
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "others-installments-input",
                          disabled: _vm.readonly,
                          options: _vm.inputOptions["installments"],
                          state: _vm.checkField("kindConfigurations"),
                          value:
                            _vm.formData.kindConfigurations.OTHERS.installments,
                          "data-test": "others-installments-input",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.kindConfig(
                              "OTHERS",
                              "installments",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }