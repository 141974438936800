import {
  maxLength,
  minLength,
  maxValue,
  minValue,
  required,
  integer,
  requiredIf,
} from 'vuelidate/lib/validators';
import { PlanProductTypesEnum } from '@enums/plan';

export default {
  formData: {
    name: {
      required,
      minLength: minLength(3),
    },
    transactionCycle: {
      required,
    },
    minimumPeriodContract: {
      required,
    },
    status: {
      required,
    },
    product_type: {
      required,
    },
    description: {
      required,
      maxLength: maxLength(1000),
    },
    category: {
      required,
      minLength: minLength(3),
    },
    billingType: {
      required,
    },
    purchaseOptionPeriod: {
      integer,
      required: requiredIf((el) => {
        return el.product_type == PlanProductTypesEnum.KOVI_PROPRIO_2
      })
    },
    kindConfigurations: {
      FINE: {
        amount: {
          required,
        },
        installments: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(12),
          integer
        },
      },
      THEFT: {
        amount: {
          required,
        },
        installments: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(12),
          integer
        },
      },
      OTHERS: {
        amount: {
          required,
        },
        installments: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(12),
          integer
        },
      },
      RECOVER: {
        amount: {
          required,
        },
        installments: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(12),
          integer
        },
      },
      RECURRENCY: {
        amount: {
          required,
        },
      },
      MAINTENANCE: {
        amount: {
          required,
        },
        installments: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(12),
          integer
        },
      },
      PRE_PAYMENT: {
        amount: {
          required,
        },
        installments: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(12),
          integer
        },
      },
      PRE_PAYMENT_RTO: {
        amount: {
          required,
        },
        installments: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(12),
          integer
        },
      },
      PRE_PAYMENT_PROMOTIONAL: {
        amount: {
          required,
        },
        installments: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(12),
          integer
        },
      },
      FIRST_PAYMENT: {
        amount: {
          required,
        },
      }
    },
  },
  validationGroup: ['formData.name', 'formData.description', 'formData.status'],
}
