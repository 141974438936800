<template>
  <b-row>
    <b-col sm="12" md="6">
      <b-form-group :label="$t('planModal.sections.planConfiguration.label.description')" label-for="description-input">
        <b-form-textarea
          id="description-input"
          :state="checkField('description')"
          :value="formData.description"
          data-test="description-input"
          maxlength="1000"
          rows="10"
          @input="dataInput('description', $event)"
        />
      </b-form-group>
    </b-col>
    <b-col sm="12" md="6">
      <b-row>
        <b-col sm="12">
          <b-form-group :label="$t('planModal.sections.planConfiguration.label.category')" label-for="category-input">
            <b-form-select
              id="category-input"
              :disabled="readonly"
              :options="inputOptions['category']"
              :state="checkField('category')"
              :value="formData.category"
              data-test="category-input"
              @change="dataInput('category', $event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group :label="$t('planModal.sections.planConfiguration.label.billingType')" label-for="billing-type-input">
            <b-form-radio-group
              id="billing-type-input"
              v-model="formData.billingType"
              :disabled="readonly"
              :options="inputOptions['billingType']"
              :state="checkField('billingType')"
              data-test="billing-type-input"
              name="billing-type-input"
              @input="dataInput('billingType', $event)"
            />
          </b-form-group>
          <b-form-group :label="$t('planModal.sections.planConfiguration.label.AutoService')" label-for="auto-service-input">
            <b-form-checkbox
              id="auto-service-input"
              v-model="formData.autoService"
              :state="checkField('autoService')"
              data-test="auto-service-input"
              @input="dataInput('autoService', $event)"
            >
              {{ $t('planModal.sections.planConfiguration.label.isAutoServiceOption') }}
            </b-form-checkbox>
          </b-form-group>
          <b-form-group :label="$t('planModal.sections.planConfiguration.label.isZeroKm')" label-for="is-zero-km-input">
            <b-form-checkbox
              id="is-zero-km-input"
              v-model="formData.isZeroKm"
              :disabled="readonly"
              :state="checkField('isZeroKm')"
              data-test="is-zero-km-input"
              @input="dataInput('isZeroKm', $event)"
            >
              {{ $t('planModal.sections.planConfiguration.label.isZeroKmOption') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group :label="$t('planModal.sections.planConfiguration.label.productType')" label-for="product-type-input">
            <b-form-radio-group
              id="product-type-input"
              v-model="formData.productType"
              :disabled="readonly"
              :options="inputOptions['product_type']"
              :state="checkField('product_type')"
              data-test="product-type-input"
              name="product-type-input"
              stacked
              @input="dataInput('product_type', $event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { planInputsMixin } from '../planInputsMixin';

export default {
  name: 'PlanConfiguration',
  mixins: [planInputsMixin],
};
</script>
