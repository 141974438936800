<template>
  <div>
    <b-alert
      variant="warning"
      :show="readonly"
    >
      {{ $t('planModal.alerts.updateMode') }}
    </b-alert>

    <b-row>
      <b-col>
        <b-form-group
          :label="$t('planModal.sections.planInformation.label.name')"
          label-for="name-input"
        >
          <b-form-input
            id="name-input"
            :state="checkField('name')"
            :value="formData.name"
            data-test="name-input"
            type="text"
            @update="dataInput('name', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('planModal.sections.planInformation.label.transactionCycle')"
          label-for="transaction-cycle-input"
        >
          <b-form-select
            id="transaction-cycle-input"
            :disabled="readonly"
            :options="inputOptions['transactionCycle']"
            :state="checkField('transactionCycle')"
            :value="formData.transactionCycle"
            data-test="transaction-cycle-input"
            @change="dataInput('transactionCycle', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('planModal.sections.planInformation.label.minimumPeriodContract')"
          label-for="minimum-period-contract-input"
        >
          <b-form-select
            id="minimum-period-contract-input"
            :disabled="readonly"
            :options="inputOptions['minimumPeriodContract']"
            :state="checkField('minimumPeriodContract')"
            :value="formData.minimumPeriodContract"
            data-test="minimum-period-contract-input"
            @change="dataInput('minimumPeriodContract', $event)"
          />
        </b-form-group>
        <b-form-group
          :label="$t('planModal.sections.planInformation.label.purchaseOptionPeriod')"
          label-for="purchase-option-period-input"
        >
          <b-form-input id="purchase-option-period-input"
                        type="number"
                        :value="formData.purchaseOptionPeriod"
                        data-test="purchase-option-period-input"
                        :state="checkField('purchaseOptionPeriod')"
                        @change="dataInput('purchaseOptionPeriod', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('planModal.sections.planInformation.label.status')"
          label-for="status-input"
        >
          <b-form-select
            id="status-input"
            :options="inputOptions['status']"
            :state="checkField('status')"
            :value="formData.status"
            data-test="status-input"
            @change="dataInput('status', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import { planInputsMixin } from '../planInputsMixin';
import { PlanProductTypesEnum } from '@enums/plan';

export default {
  name: 'PlanInformation',
  mixins: [planInputsMixin],
  data() {
    return {
      product_types_enum: PlanProductTypesEnum
    }
  },
}
</script>
