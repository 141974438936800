<template>
  <div v-if="formData.modifiers">
    <b-dd-divider class="mb-4" />
    <b-row>
      <b-col sm="10">
        <h5 class="text-uppercase font-weight-bold">
          {{ $t('planModal.sections.planPpmModifier.title') }}
        </h5>
      </b-col>
      <b-col cols="4" v-if="!readonly">
        <b-button
          variant="success"
          @click="newRange"
        >
          <i class="fa fa-plus" aria-hidden="true" /> Nova Faixa
        </b-button>

        <b-button
          variant="success"
          class="ml-2"
          @click="rangeDefault"
          :disabled="modifiers['PAY_PER_MILE'].ranges.length !== 0"
        >
          <i class="fa fa-plus" aria-hidden="true" /> Faixa Padrão
        </b-button>
      </b-col>
      <b-col cols="12">
        <b-dd-divider class="mb-1" />
      </b-col>
    </b-row>

    <b-row
      v-for="(modifier, index) in formData.modifiers['PAY_PER_MILE'].ranges"
      :key="index"
    >
      <!-- modifier: {{ index !== 0 ? formData.modifiers['PAY_PER_MILE'].ranges[index - 1].end : 0 }} -->
      <b-col
        md="3"
        sm="4"
        lg="3"
      >
        <b-form-group
          class="font-weight-bold"
          label="Início da Faixa"
          :label-for="`pay-per-mile-start-${index}`"
        >
          <b-input-group
            :id="`pay-per-mile-start-${index}`"
            prepend="(Km)"
            class="mt-3"
          >
            <b-form-input
              v-model="modifier.start"
              :value="getEnds(index)"
              disabled
              @input="modifierStart(index)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col
        md="3"
        sm="4"
        lg="3"
      >
        <b-form-group
          label="Fim da Faixa"
          class="font-weight-bold"
          :label-for="`pay-per-mile-end-${index}`"
        >
          <b-input-group
            prepend="(Km)"
            class="mt-3"
          >
            <b-form-input
              :id="`pay-per-mile-end-${index}`"
              v-model="modifier.end"
              :disabled="readonly"
              :state="validationEndRange(modifier, index)"
              @input="modifierEnd(index)"
            />
            <b-form-invalid-feedback :id="`pay-per-mile-end-${index}-feedback`">
              Faixa Incorreta!
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col
        md="3"
        sm="4"
        lg="3"
      >
        <b-form-group
          :label="getLabel(modifier, index)"
          :label-for="`pay-per-mile-input-${index}`"
          class="font-weight-bold pt-2"
        >
          <b-input-group :prepend="$t('planModal.labels.money')">
            <the-mask
              :id="`pay-per-mile-input-${index}`"
              :data-test="`pay-per-mile-input-${index}`"
              :disabled="readonly"
              :mask="[...maskThousand]"
              :value="modifier.value"
              class="form-control"
              @input="modifierConfig(index, $event)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col class="mt-4" v-if="!readonly">
        <!-- len:({{ modifiersLength }}) / index: ({{index}}) -->
        <b-button
          v-if="modifiersLength === (index + 1)"
          variant="danger"
          @click="deleteRange"
        >
          <i class="fa fa-trash" aria-hidden="true" />
        </b-button>
      </b-col>
      <!-- <p>{{ formData.modifiers['PAY_PER_MILE'].ranges }}</p> -->
      <b-col cols="12">
        <b-dd-divider class="mb-1" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { planInputsMixin } from '@components/modals/plan/planInputsMixin';
  import { modifiers } from '@components/modals/plan/planInputsObject';

  export default {
    name: 'PlanPpmModifier',
    mixins: [planInputsMixin],
    data: () => ({
      modifiers: { ...modifiers },
    }),
    computed: {
      modifiersLength () {
        return this.formData.modifiers['PAY_PER_MILE'].ranges.length
      },
    },
    destroyed () {
      this.modifiers['PAY_PER_MILE'].ranges = []
      this.modifierStart()
    },
    methods: {
      getLabel(modifier, index) {
        let rangeText = modifier.start === 0
          ? `< ${modifier.end}`
          : modifier.end === 0
            ? `> ${modifier.start}`
            : `${modifier.start} - ${modifier.end}`;

        return `Faixa ${index} (${rangeText}km)`;
      },
      emitChanges () {
        this.$emit('change', this.modifiers)
      },
      modifierConfig(index, event) {
        this.modifiers['PAY_PER_MILE'].ranges[index].value = parseInt(event);
        this.emitChanges()
      },
      modifierStart() {
        this.emitChanges()
      },
      modifierEnd() {
        this.emitChanges()
      },
      getEnds (index) {
        if (index !== 0) {
          this.formData.modifiers['PAY_PER_MILE'].ranges[index].start = parseInt(this.formData.modifiers['PAY_PER_MILE'].ranges[index - 1].end) + 1
        }
        return this.formData.modifiers['PAY_PER_MILE'].ranges[index].start
      },
      newRange () {
        this.modifiers['PAY_PER_MILE'].ranges.push({
          start: 0,
          end: 0,
          value: '',
        })
        this.emitChanges()
      },
      deleteRange () {
        this.modifiers['PAY_PER_MILE'].ranges.pop()
        this.emitChanges()
      },
      validationEndRange (modifierInput, index) {
        let start = parseInt(modifierInput.start)
        let end = parseInt(modifierInput.end)
        if(this.formData.modifiers['PAY_PER_MILE'].ranges.length - 1 !== index) {
          return end > start
        } else {
          if (end !== 0) {
            console.log('PASSOU');
            return end > start
          }
          return true
        }
      },
      rangeDefault () {
        this.modifiers['PAY_PER_MILE'].ranges = [{
          start: 0,
          end: 100,
          value: '',
        },
        {
          start: 101,
          end: 500,
          value: '',
        },
        {
          start: 501,
          end: 850,
          value: '',
        },
        {
          start: 851,
          end: 1250,
          value: '',
        },
        {
          start: 1250,
          end: 0,
          value: '',
        }]
        this.emitChanges()
      }
    },
  }
</script>
