<template>
  <div v-if="formData.kindConfigurations">
    <b-dd-divider class="mb-4" />

    <b-row>
      <b-col sm="12">
        <h5 class="text-uppercase font-weight-bold">
          {{ $t('planModal.sections.planInstallments.title') }}
        </h5>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          :label="$t('planModal.kindConfigurations.FINE.installments')"
          label-for="fine-installments-input"
        >
          <b-form-select
            id="fine-installments-input"
            :disabled="readonly"
            :options="inputOptions['installments']"
            :state="checkField('kindConfigurations')"
            :value="formData.kindConfigurations.FINE.installments"
            data-test="fine-installments-input"
            @input="kindConfig('FINE', 'installments', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('planModal.kindConfigurations.MAINTENANCE.installments')"
          label-for="maintenance-installments-input"
        >
          <b-form-select
            id="maintenance-installments-input"
            :disabled="readonly"
            :options="inputOptions['installments']"
            :state="checkField('kindConfigurations')"
            :value="formData.kindConfigurations.MAINTENANCE.installments"
            data-test="maintenance-installments-input"
            @input="kindConfig('MAINTENANCE', 'installments', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('planModal.kindConfigurations.RECOVER.installments')"
          label-for="recover-installments-input"
        >
          <b-form-select
            id="recover-installments-input"
            :disabled="readonly"
            :options="inputOptions['installments']"
            :state="checkField('kindConfigurations')"
            :value="formData.kindConfigurations.RECOVER.installments"
            data-test="recover-installments-input"
            @input="kindConfig('RECOVER', 'installments', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('planModal.kindConfigurations.THEFT.installments')"
          label-for="theft-installments-input"
        >
          <b-form-select
            id="theft-installments-input"
            :disabled="readonly"
            :options="inputOptions['installments']"
            :state="checkField('kindConfigurations')"
            :value="formData.kindConfigurations.THEFT.installments"
            data-test="theft-installments-input"
            @input="kindConfig('THEFT', 'installments', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('planModal.kindConfigurations.SUBSCRIPTION_TERMINATION.installments')"
          label-for="subscription-termination-installments-input"
        >
          <b-form-select
            id="subscription-termination-installments-input"
            :disabled="readonly"
            :options="inputOptions['installments']"
            :state="checkField('kindConfigurations')"
            :value="formData.kindConfigurations.SUBSCRIPTION_TERMINATION.installments"
            data-test="subscription-termination-installments-input"
            @input="kindConfig('SUBSCRIPTION_TERMINATION', 'installments', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('planModal.kindConfigurations.OTHERS.installments')"
          label-for="others-installments-input"
        >
          <b-form-select
            id="others-installments-input"
            :disabled="readonly"
            :options="inputOptions['installments']"
            :state="checkField('kindConfigurations')"
            :value="formData.kindConfigurations.OTHERS.installments"
            data-test="others-installments-input"
            @input="kindConfig('OTHERS', 'installments', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import { planInputsMixin } from '../planInputsMixin';

export default {
  name: 'PlanInstallments',
  mixins: [ planInputsMixin ],
}

</script>
