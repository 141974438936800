var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.formData.modifiers
    ? _c(
        "div",
        [
          _c("b-dd-divider", { staticClass: "mb-4" }),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { sm: "10" } }, [
                _c("h5", { staticClass: "text-uppercase font-weight-bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("planModal.sections.planPpmModifier.title")
                      ) +
                      " "
                  ),
                ]),
              ]),
              !_vm.readonly
                ? _c(
                    "b-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "success" },
                          on: { click: _vm.newRange },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-plus",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(" Nova Faixa "),
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            variant: "success",
                            disabled:
                              _vm.modifiers["PAY_PER_MILE"].ranges.length !== 0,
                          },
                          on: { click: _vm.rangeDefault },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-plus",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(" Faixa Padrão "),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [_c("b-dd-divider", { staticClass: "mb-1" })],
                1
              ),
            ],
            1
          ),
          _vm._l(
            _vm.formData.modifiers["PAY_PER_MILE"].ranges,
            function (modifier, index) {
              return _c(
                "b-row",
                { key: index },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "3", sm: "4", lg: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "font-weight-bold",
                          attrs: {
                            label: "Início da Faixa",
                            "label-for": `pay-per-mile-start-${index}`,
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              staticClass: "mt-3",
                              attrs: {
                                id: `pay-per-mile-start-${index}`,
                                prepend: "(Km)",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  value: _vm.getEnds(index),
                                  disabled: "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.modifierStart(index)
                                  },
                                },
                                model: {
                                  value: modifier.start,
                                  callback: function ($$v) {
                                    _vm.$set(modifier, "start", $$v)
                                  },
                                  expression: "modifier.start",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3", sm: "4", lg: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "font-weight-bold",
                          attrs: {
                            label: "Fim da Faixa",
                            "label-for": `pay-per-mile-end-${index}`,
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            { staticClass: "mt-3", attrs: { prepend: "(Km)" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: `pay-per-mile-end-${index}`,
                                  disabled: _vm.readonly,
                                  state: _vm.validationEndRange(
                                    modifier,
                                    index
                                  ),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.modifierEnd(index)
                                  },
                                },
                                model: {
                                  value: modifier.end,
                                  callback: function ($$v) {
                                    _vm.$set(modifier, "end", $$v)
                                  },
                                  expression: "modifier.end",
                                },
                              }),
                              _c(
                                "b-form-invalid-feedback",
                                {
                                  attrs: {
                                    id: `pay-per-mile-end-${index}-feedback`,
                                  },
                                },
                                [_vm._v(" Faixa Incorreta! ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3", sm: "4", lg: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "font-weight-bold pt-2",
                          attrs: {
                            label: _vm.getLabel(modifier, index),
                            "label-for": `pay-per-mile-input-${index}`,
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              attrs: {
                                prepend: _vm.$t("planModal.labels.money"),
                              },
                            },
                            [
                              _c("the-mask", {
                                staticClass: "form-control",
                                attrs: {
                                  id: `pay-per-mile-input-${index}`,
                                  "data-test": `pay-per-mile-input-${index}`,
                                  disabled: _vm.readonly,
                                  mask: [..._vm.maskThousand],
                                  value: modifier.value,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.modifierConfig(index, $event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.readonly
                    ? _c(
                        "b-col",
                        { staticClass: "mt-4" },
                        [
                          _vm.modifiersLength === index + 1
                            ? _c(
                                "b-button",
                                {
                                  attrs: { variant: "danger" },
                                  on: { click: _vm.deleteRange },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-trash",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [_c("b-dd-divider", { staticClass: "mb-1" })],
                    1
                  ),
                ],
                1
              )
            }
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }