<template>
  <div v-if="formData.kindConfigurations">
    <b-row>
      <b-col>
        <b-form-group
          :label="$t('planModal.kindConfigurations.PRE_PAYMENT.amount')"
          label-for="pre-payment-amount-input"
        >
          <b-input-group :prepend="$t('planModal.labels.money')">
            <the-mask
              id="pre-payment-amount-input"
              :disabled="readonly"
              :class="getValidationClass('kindConfigurations')"
              :mask="[...maskThousand]"
              :value="formData.kindConfigurations.PRE_PAYMENT.amount"
              class="form-control"
              data-test="pre-payment-amount-input"
              @input="kindConfig('PRE_PAYMENT', 'amount', parseInt($event))"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('planModal.kindConfigurations.PRE_PAYMENT.installments')"
          label-for="pre-payment-installments-input"
        >
          <b-form-select
            id="pre-payment-installments-input"
            :disabled="readonly"
            :options="inputOptions['installments']"
            :state="checkField('kindConfigurations')"
            :value="formData.kindConfigurations.PRE_PAYMENT.installments"
            data-test="pre-payment-installments-input"
            @input="kindConfig('PRE_PAYMENT', 'installments', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('planModal.kindConfigurations.RECURRENCY.amount')"
          label-for="recurrency-amount-input"
        >
          <b-input-group :prepend="$t('planModal.labels.money')">
            <the-mask
              id="recurrency-amount-input"
              :class="getValidationClass('kindConfigurations')"
              :disabled="readonly"
              :mask="[...maskThousand]"
              :value="formData.kindConfigurations.RECURRENCY.amount"
              class="form-control"
              data-test="recurrency-amount-input"
              @input="kindConfig('RECURRENCY', 'amount', parseInt($event))"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('planModal.kindConfigurations.FIRST_PAYMENT.amount')"
          label-for="first-payment-amount-input"
        >
          <b-input-group :prepend="$t('planModal.labels.money')">
            <the-mask
              id="first-payment-amount-input"
              :class="getValidationClass('kindConfigurations')"
              :disabled="readonly"
              :mask="[...maskThousand]"
              :value="formData.kindConfigurations.FIRST_PAYMENT.amount"
              class="form-control"
              data-test="first-payment-amount-input"
              @input="kindConfig('FIRST_PAYMENT', 'amount', parseInt($event))"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="$t('planModal.kindConfigurations.SUBSCRIPTION_TERMINATION.amount')"
          label-for="subscription-termination-amount-input"
        >
          <b-input-group :prepend="$t('planModal.labels.money')">
            <the-mask
              id="subscription-termination-amount-input"
              :class="getValidationClass('kindConfigurations')"
              :disabled="readonly"
              :mask="[...maskThousand]"
              :value="formData.kindConfigurations.SUBSCRIPTION_TERMINATION.amount"
              class="form-control"
              data-test="subscription-termination-amount-input"
              @input="kindConfig('SUBSCRIPTION_TERMINATION', 'amount', parseInt($event))"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import { planInputsMixin } from '../planInputsMixin';

export default {
  name: 'PlanBilling',
  mixins: [planInputsMixin],
}
</script>
