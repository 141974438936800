var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-alert", { attrs: { variant: "warning", show: _vm.readonly } }, [
        _vm._v(" " + _vm._s(_vm.$t("planModal.alerts.updateMode")) + " "),
      ]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "planModal.sections.planInformation.label.name"
                    ),
                    "label-for": "name-input",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "name-input",
                      state: _vm.checkField("name"),
                      value: _vm.formData.name,
                      "data-test": "name-input",
                      type: "text",
                    },
                    on: {
                      update: function ($event) {
                        return _vm.dataInput("name", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "planModal.sections.planInformation.label.transactionCycle"
                    ),
                    "label-for": "transaction-cycle-input",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "transaction-cycle-input",
                      disabled: _vm.readonly,
                      options: _vm.inputOptions["transactionCycle"],
                      state: _vm.checkField("transactionCycle"),
                      value: _vm.formData.transactionCycle,
                      "data-test": "transaction-cycle-input",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.dataInput("transactionCycle", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "planModal.sections.planInformation.label.minimumPeriodContract"
                    ),
                    "label-for": "minimum-period-contract-input",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "minimum-period-contract-input",
                      disabled: _vm.readonly,
                      options: _vm.inputOptions["minimumPeriodContract"],
                      state: _vm.checkField("minimumPeriodContract"),
                      value: _vm.formData.minimumPeriodContract,
                      "data-test": "minimum-period-contract-input",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.dataInput("minimumPeriodContract", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "planModal.sections.planInformation.label.purchaseOptionPeriod"
                    ),
                    "label-for": "purchase-option-period-input",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "purchase-option-period-input",
                      type: "number",
                      value: _vm.formData.purchaseOptionPeriod,
                      "data-test": "purchase-option-period-input",
                      state: _vm.checkField("purchaseOptionPeriod"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.dataInput("purchaseOptionPeriod", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "planModal.sections.planInformation.label.status"
                    ),
                    "label-for": "status-input",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "status-input",
                      options: _vm.inputOptions["status"],
                      state: _vm.checkField("status"),
                      value: _vm.formData.status,
                      "data-test": "status-input",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.dataInput("status", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }