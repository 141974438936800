var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Layout", [
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        _c(
          "b-row",
          { staticClass: "pb-4" },
          [
            _c("b-col", { attrs: { cols: "6" } }, [
              _c("h2", [_vm._v("Planos")]),
            ]),
            _c(
              "b-col",
              { attrs: { cols: "6" } },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "float-right",
                    attrs: { variant: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.goTo(null)
                      },
                    },
                  },
                  [_vm._v(" Novo plano ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-card",
          { attrs: { "body-class": "p-0" } },
          [
            _c(
              "b-card-body",
              { staticClass: "p-0 plans-table-format" },
              [
                _c("custom-data-table", {
                  ref: "plans_table",
                  attrs: {
                    fields: _vm.fields,
                    filters: _vm.filters,
                    query: _vm.planListQL,
                    "query-node": "plans",
                  },
                  on: { "vuetable:row-clicked": _vm.goTo },
                  scopedSlots: _vm._u([
                    {
                      key: "name",
                      fn: function (props) {
                        return _c("span", {}, [
                          _c("strong", [_vm._v(_vm._s(props.rowData.name))]),
                          _c("br"),
                          _c("span", { staticClass: "small text-muted" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.setFuelFormat(props.rowData.fuel)) +
                                " "
                            ),
                          ]),
                          _c("br"),
                        ])
                      },
                    },
                    {
                      key: "auto_service",
                      fn: function (props) {
                        return _c("span", {}, [
                          _vm._v(
                            " " +
                              _vm._s(
                                props.rowData.auto_service
                                  ? _vm.$t(
                                      "planModal.enums.serviceType.auto_service"
                                    )
                                  : _vm.$t(
                                      "planModal.enums.serviceType.standard"
                                    )
                              ) +
                              " "
                          ),
                        ])
                      },
                    },
                    {
                      key: "kind_configurations_recurrency",
                      fn: function (props) {
                        return _c("span", {}, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.setPriceFormat(
                                  props.rowData.kind_configurations[
                                    "RECURRENCY"
                                  ].amount
                                )
                              ) +
                              " "
                          ),
                        ])
                      },
                    },
                    {
                      key: "kind_configurations_payment",
                      fn: function (props) {
                        return _c("span", {}, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.setPriceFormat(
                                  props.rowData.kind_configurations[
                                    "PRE_PAYMENT"
                                  ].amount
                                )
                              ) +
                              " "
                          ),
                        ])
                      },
                    },
                    {
                      key: "pay_per_mile",
                      fn: function (props) {
                        return _c("span", {}, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.checkModifiersValue(props.rowData.modifiers)
                                  ? "SIM"
                                  : "NÃO"
                              ) +
                              " "
                          ),
                        ])
                      },
                    },
                    {
                      key: "created_at",
                      fn: function (props) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("moment")(
                                  props.rowData.created_at,
                                  "DD/MM/YYYY"
                                )
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "status",
                      fn: function (props) {
                        return _c(
                          "span",
                          {},
                          [
                            _c(
                              "b-badge",
                              {
                                attrs: {
                                  variant: _vm.setStatusBadge(
                                    props.rowData.status
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          `planModal.enums.planStatusEnum.${props.rowData.status}`
                                        )
                                        .toUpperCase()
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                }),
                _c("modal-plan", {
                  attrs: { "modal-data": _vm.plan_modal_data },
                  on: {
                    success: function ($event) {
                      return _vm.refreshData()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }