import { mutate } from '@utils/apollo';
import createMutation from '@graphql/plan/mutations/create.gql';
import updateMutation from '@graphql/plan/mutations/update.gql';
import transformObjectKeys from 'transform-object-keys';
import { PlanProductEnum, PlanProductTypesEnum } from '@enums/plan';

export const create = (payload = {}, graphqlQuery = createMutation) => {
  const variables = transformObjectKeys(payload, { deep: false, snakeCase: true });

  const product = [
    PlanProductTypesEnum.KOVI_PROPRIO,
    PlanProductTypesEnum.KOVI_PROPRIO_2,
  ].includes(variables.product_type) ? PlanProductEnum.RENT_TO_OWN : PlanProductEnum.RENTAL
  variables.product = product;

  const planInput = {
    planInput: variables
  }

  return mutate(
    graphqlQuery,
    planInput,
  );
}

export const update = (payload = {}, graphqlQuery = updateMutation) => {
  const variables = transformObjectKeys(payload, { deep: false, snakeCase: true });

  const planInput = {
    id: variables.id,
    planInput: {
      name: variables.name,
      description: variables.description,
      status: variables.status,
      auto_service: variables.auto_service,
    }
  }

  return mutate(
    graphqlQuery,
    planInput,
  );
}
