import { i18next } from '@src/translate/i18n';
import planInputsObject, { kindConfigurations } from '@components/modals/plan/planInputsObject';
import {
  BillingTypeEnum,
  MinimumPeriodContractEnum,
  PlanStatusEnum,
  TransactionCycleEnum,
} from '@enums/plan';
import { enumCarsCategory } from '@utils/car'
import { enumProductType } from '@utils/plan';


export const planInputsMixin = {
  props: {
    defaultInstallmentsQty: {
      type: Number,
      required: false,
      default: 12
    },
    formData: {
      type: Object,
      default: () => ({
        ...planInputsObject
      }),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    validator: {
      type: Object,
      default: () => { },
    },
    submitClick: {
      type: Boolean,
      default: false,
    },
    formMode: {
      type: String,
      default: 'create'
    }
  },
  data() {
    return {
      kindConfigurations: { ...kindConfigurations },
      maskThousand: [
        '#####,##',
        '####,##',
        '###,##',
        '##,##',
        '#,##'
      ]
    };
  },
  computed: {
    installmentsArr() {
      return Array(this.defaultInstallmentsQty)
        .fill({
          value: 0,
          text: '',
        })
        .map((_, index) => ({
          value: index + 1,
          text: `${index + 1}x`,
        }))
    },
    inputOptions() {
      return {
        category: enumCarsCategory,
        product_type: enumProductType,
        transactionCycle: [
          {
            value: TransactionCycleEnum.WEEKLY,
            text: this.$t('planModal.enums.transactionCycleEnum.WEEKLY'),
          },
        ],
        minimumPeriodContract: [
          {
            value: MinimumPeriodContractEnum.MONTHLY,
            text: this.$t('planModal.enums.minimumPeriodContractEnum.MONTHLY'),
          },
          {
            value: MinimumPeriodContractEnum.ANNUAL,
            text: this.$t('planModal.enums.minimumPeriodContractEnum.ANNUAL'),
          },
        ],
        status: [
          {
            value: PlanStatusEnum.ACTIVE,
            text: i18next.t('planModal.enums.planStatusEnum.ACTIVE')
          },
          {
            value: PlanStatusEnum.PAUSED,
            text: i18next.t('planModal.enums.planStatusEnum.PAUSED')
          },
          {
            value: PlanStatusEnum.RETIRED,
            text: i18next.t('planModal.enums.planStatusEnum.RETIRED')
          },
          {
            value: PlanStatusEnum.INACTIVE,
            text: i18next.t('planModal.enums.planStatusEnum.INACTIVE')
          },
        ],
        installments: this.installmentsArr,
        billingType: [
          {
            value: BillingTypeEnum.PRE_PAY,
            text: this.$t('planModal.enums.billingTypeEnum.PRE_PAY')
          },
          {
            value: BillingTypeEnum.POS_PAY,
            text: this.$t('planModal.enums.billingTypeEnum.POS_PAY'),
            disabled: true
          }
        ]
      }
    }

  },
  methods: {
    checkField(field) {
      return this.submitClick
        ? !this.validator.formData[field]?.$error
        : null;
    },
    dataInput(inputName, value) {
      this.$emit('change', {
        name: inputName,
        data: value,
      })
    },
    kindConfig(index, prop, data) {
      if (index === 'PRE_PAYMENT' && prop === 'amount') {
        this.kindConfigurations['PRE_PAYMENT_RTO']['amount'] = data;
      }
      if (index === 'FIRST_PAYMENT' && prop === 'amount') {
        this.kindConfigurations['FIRST_PAYMENT_EXCHANGE']['amount'] = data;
      }
      this.kindConfigurations[index][prop] = data;
      this.$emit('change', {
        name: 'kindConfigurations',
        data: this.kindConfigurations,
      });
    },
    getValidationClass(prop) {
      return this.checkField(prop) === true
        ? 'is-valid'
        : this.checkField(prop) === false
          ? 'is-invalid'
          : '';
    },
  },
}
