import { PlanProductTypesEnum } from '@enums/plan';
import { i18next } from '../translate/i18n';

export const formatStatus = status => {
  return status === 'ACTIVE'
    ? 'success'
    : status === 'PAUSED'
      ? 'warning'
      : status === 'RETIRED'
        ? 'secondary'
        : 'primary'
}

export const enumStatus = [
  { text: 'Ativo', value: 'ACTIVE' },
  { text: 'Pausado', value: 'PAUSED' },
  { text: 'Arquivado', value: 'RETIRED' },
]

export const enumProductType = [
  {

    text: i18next.t(`planModal.enums.productTypeEnum.KOVI_PROPRIO`),
    value: PlanProductTypesEnum.KOVI_PROPRIO,
  },
  {

    text: i18next.t(`planModal.enums.productTypeEnum.KOVI_PROPRIO_2`),
    value: PlanProductTypesEnum.KOVI_PROPRIO_2,
  },
  {
    text: i18next.t(`planModal.enums.productTypeEnum.KOVI_MENSAL`),
    value: PlanProductTypesEnum.KOVI_MENSAL,
  },
  {
    text: i18next.t(`planModal.enums.productTypeEnum.KOVI_ANUAL`),
    value: PlanProductTypesEnum.KOVI_ANUAL,
  },
];

export const enumCycle = [
  { text: 'Mensal (28 dias)', value: '28' },
  { text: 'Quinzenal (14 dias)', value: '14' },
  { text: 'Semanal (7 dias)', value: '7' },
  { text: 'Diário (1 dia)', value: '1' },
]

export const enumInstallments = [
  { text: '1x', value: '1' },
  { text: '2x', value: '2' },
  { text: '3x', value: '3' },
  { text: '4x', value: '4' },
  { text: '5x', value: '5' },
  { text: '6x', value: '6' },
]
