<template>
  <Layout>
    <div class="animated fadeIn">
      <b-row class="pb-4">
        <b-col cols="6">
          <h2>Planos</h2>
        </b-col>
        <b-col cols="6">
          <b-button variant="primary" class="float-right" @click="goTo(null)"> Novo plano </b-button>
        </b-col>
      </b-row>
      <b-card body-class="p-0">
        <b-card-body class="p-0 plans-table-format">
          <custom-data-table ref="plans_table" :fields="fields" :filters="filters" :query="planListQL" :query-node="'plans'" @vuetable:row-clicked="goTo">
            <span slot="name" slot-scope="props">
              <strong>{{ props.rowData.name }}</strong>
              <br />
              <span class="small text-muted">
                {{ setFuelFormat(props.rowData.fuel) }}
              </span>
              <br />
            </span>

            <span slot="auto_service" slot-scope="props">
              {{ props.rowData.auto_service ? $t('planModal.enums.serviceType.auto_service') : $t('planModal.enums.serviceType.standard') }}
            </span>

            <span slot="kind_configurations_recurrency" slot-scope="props">
              {{ setPriceFormat(props.rowData.kind_configurations['RECURRENCY'].amount) }}
            </span>

            <span slot="kind_configurations_payment" slot-scope="props">
              {{ setPriceFormat(props.rowData.kind_configurations['PRE_PAYMENT'].amount) }}
            </span>

            <span slot="pay_per_mile" slot-scope="props">
              {{ checkModifiersValue(props.rowData.modifiers) ? 'SIM' : 'NÃO' }}
            </span>

            <template slot="created_at" slot-scope="props">
              {{ props.rowData.created_at | moment('DD/MM/YYYY') }}
            </template>

            <span slot="status" slot-scope="props">
              <b-badge :variant="setStatusBadge(props.rowData.status)">
                {{ $t(`planModal.enums.planStatusEnum.${props.rowData.status}`).toUpperCase() }}
              </b-badge>
            </span>
          </custom-data-table>
          <modal-plan :modal-data="plan_modal_data" @success="refreshData()" />
        </b-card-body>
      </b-card>
    </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/main';
import ModalPlan from '@components/modals/plan/planModal';
import CustomDataTable from '@components/CustomDataTable';
import { formatPrice, formatNumber } from '@utils/numbers';
import { formatFuel } from '@utils/car';
import { formatStatus } from '@utils/plan';
import transformObjectKeys from 'transform-object-keys';

import PLAN_LIST from '@graphql/plan/queries/list.gql';
import planInputsObject from '@components/modals/plan/planInputsObject';
import { checkModifiersValue } from '@components/modals/plan/commons/propType';

export default {
  name: 'AdminPlans',
  page: {
    title: 'Administrar Planos',
  },
  components: {
    Layout,
    ModalPlan,
    CustomDataTable,
  },
  data() {
    return {
      fields: [
        {
          name: 'name',
          title: 'Nome do plano',
          sortField: 'name',
        },
        {
          name: 'auto_service',
          title: this.$t('planModal.sections.planConfiguration.label.AutoService'),
        },
        {
          name: `kind_configurations_recurrency`,
          title: 'Valor plano',
        },
        {
          name: `kind_configurations_payment`,
          title: 'Valor caução',
        },
        {
          name: `pay_per_mile`,
          title: 'Pay Per Mile',
        },
        {
          name: `created_at`,
          title: 'Criado em',
          sortField: 'name',
        },
        {
          name: 'status',
          title: 'Status',
          class: 'text-right',
        },
      ],
      planListQL: PLAN_LIST,
      plan_modal_data: {
        ...planInputsObject,
      },
    };
  },
  computed: {
    filters() {
      return {
        name: {
          type: 'textfield',
          props: {
            label: '',
            placeholder: 'Buscar por nome',
          },
          graphqlQuery(value) {
            return {
              $or: [
                {
                  name: {
                    $like: `%${value}%`,
                  },
                },
              ],
            };
          },
        },
        status: {
          type: 'select',
          props: {
            label: 'Status',
            options: [
              {
                value: ['ACTIVE', 'PAUSED', 'RETIRED'],
                text: 'TODOS',
              },
              {
                value: 'ACTIVE',
                text: 'Ativo',
              },
              {
                value: 'PAUSED',
                text: 'Pausado',
              },
              {
                value: 'RETIRED',
                text: 'Arquivado',
              },
            ],
          },
          valueParserType: String,
          graphqlQuery(value) {
            return {
              status: value,
            };
          },
        },
        autoservice: {
          type: 'select',
          props: {
            label: this.$t('planModal.sections.planConfiguration.label.AutoService'),
            options: [
              { value: -1, text: this.$t('planModal.enums.serviceType.all') },
              { value: 0, text: this.$t('planModal.enums.serviceType.standard') },
              { value: 1, text: this.$t('planModal.enums.serviceType.auto_service') },
            ],
          },
          graphqlQuery(value) {
            if (value === -1) return {};
            return {
              auto_service: Boolean(value),
            };
          },
        },
      };
    },
  },
  methods: {
    checkModifiersValue: checkModifiersValue,
    refreshData() {
      this.$apollo.queries.plans.refresh();
    },
    goTo(data = null) {
      const item = data?.data || null;
      if (!item) {
        this.$root.$emit('bv::hide::modal', 'modalPlan');
        this.plan_modal_data.id = null;
      } else {
        this.plan_modal_data = transformObjectKeys(item);
      }
      this.$root.$emit('bv::show::modal', 'plan-modal');
    },
    setFuelFormat(_fuel, _spacer) {
      return formatFuel(_fuel, _spacer);
    },
    setKmFormat(_value) {
      return formatNumber(_value) + 'km';
    },
    setPriceFormat(_value) {
      return formatPrice('R$', _value);
    },
    setNumberFormat(_value) {
      return formatNumber(_value);
    },
    setStatusBadge(_status) {
      return formatStatus(_status);
    },
    formatDate(_overdue_limit, _timezone) {
      const utc = this.$moment.utc(_overdue_limit);
      return this.$moment.tz(utc, _timezone).format('DD/MM/YYYY');
    },
  },
};
</script>

<style>
.plans-table-format .custom-data-table .custom-data-table__filters {
  margin: 20px 20px 10px 20px !important;
}
</style>
