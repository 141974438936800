var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { sm: "12", md: "6" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t(
                  "planModal.sections.planConfiguration.label.description"
                ),
                "label-for": "description-input",
              },
            },
            [
              _c("b-form-textarea", {
                attrs: {
                  id: "description-input",
                  state: _vm.checkField("description"),
                  value: _vm.formData.description,
                  "data-test": "description-input",
                  maxlength: "1000",
                  rows: "10",
                },
                on: {
                  input: function ($event) {
                    return _vm.dataInput("description", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-col",
        { attrs: { sm: "12", md: "6" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t(
                          "planModal.sections.planConfiguration.label.category"
                        ),
                        "label-for": "category-input",
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "category-input",
                          disabled: _vm.readonly,
                          options: _vm.inputOptions["category"],
                          state: _vm.checkField("category"),
                          value: _vm.formData.category,
                          "data-test": "category-input",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.dataInput("category", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12", md: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t(
                          "planModal.sections.planConfiguration.label.billingType"
                        ),
                        "label-for": "billing-type-input",
                      },
                    },
                    [
                      _c("b-form-radio-group", {
                        attrs: {
                          id: "billing-type-input",
                          disabled: _vm.readonly,
                          options: _vm.inputOptions["billingType"],
                          state: _vm.checkField("billingType"),
                          "data-test": "billing-type-input",
                          name: "billing-type-input",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.dataInput("billingType", $event)
                          },
                        },
                        model: {
                          value: _vm.formData.billingType,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "billingType", $$v)
                          },
                          expression: "formData.billingType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t(
                          "planModal.sections.planConfiguration.label.AutoService"
                        ),
                        "label-for": "auto-service-input",
                      },
                    },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            id: "auto-service-input",
                            state: _vm.checkField("autoService"),
                            "data-test": "auto-service-input",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.dataInput("autoService", $event)
                            },
                          },
                          model: {
                            value: _vm.formData.autoService,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "autoService", $$v)
                            },
                            expression: "formData.autoService",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "planModal.sections.planConfiguration.label.isAutoServiceOption"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t(
                          "planModal.sections.planConfiguration.label.isZeroKm"
                        ),
                        "label-for": "is-zero-km-input",
                      },
                    },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            id: "is-zero-km-input",
                            disabled: _vm.readonly,
                            state: _vm.checkField("isZeroKm"),
                            "data-test": "is-zero-km-input",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.dataInput("isZeroKm", $event)
                            },
                          },
                          model: {
                            value: _vm.formData.isZeroKm,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "isZeroKm", $$v)
                            },
                            expression: "formData.isZeroKm",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "planModal.sections.planConfiguration.label.isZeroKmOption"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t(
                          "planModal.sections.planConfiguration.label.productType"
                        ),
                        "label-for": "product-type-input",
                      },
                    },
                    [
                      _c("b-form-radio-group", {
                        attrs: {
                          id: "product-type-input",
                          disabled: _vm.readonly,
                          options: _vm.inputOptions["product_type"],
                          state: _vm.checkField("product_type"),
                          "data-test": "product-type-input",
                          name: "product-type-input",
                          stacked: "",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.dataInput("product_type", $event)
                          },
                        },
                        model: {
                          value: _vm.formData.productType,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "productType", $$v)
                          },
                          expression: "formData.productType",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }