export const booleanProp = {
  type: Boolean,
  default: false,
};

export const objectProp = {
  type: Object,
  default: () => {},
};

export const checkModifiersValue = (modifiers = {'PAY_PER_MILE': { ranges: [] }}) => {
  const hasValue = ({ value }) => !!value;
  
  return (
    !!modifiers &&
    modifiers['PAY_PER_MILE'].ranges.length
    && modifiers['PAY_PER_MILE'].ranges.some(hasValue)
  );
};
