var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.formData.kindConfigurations
    ? _c(
        "div",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t(
                          "planModal.kindConfigurations.PRE_PAYMENT.amount"
                        ),
                        "label-for": "pre-payment-amount-input",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        {
                          attrs: { prepend: _vm.$t("planModal.labels.money") },
                        },
                        [
                          _c("the-mask", {
                            staticClass: "form-control",
                            class: _vm.getValidationClass("kindConfigurations"),
                            attrs: {
                              id: "pre-payment-amount-input",
                              disabled: _vm.readonly,
                              mask: [..._vm.maskThousand],
                              value:
                                _vm.formData.kindConfigurations.PRE_PAYMENT
                                  .amount,
                              "data-test": "pre-payment-amount-input",
                            },
                            on: {
                              input: function ($event) {
                                _vm.kindConfig(
                                  "PRE_PAYMENT",
                                  "amount",
                                  parseInt($event)
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t(
                          "planModal.kindConfigurations.PRE_PAYMENT.installments"
                        ),
                        "label-for": "pre-payment-installments-input",
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "pre-payment-installments-input",
                          disabled: _vm.readonly,
                          options: _vm.inputOptions["installments"],
                          state: _vm.checkField("kindConfigurations"),
                          value:
                            _vm.formData.kindConfigurations.PRE_PAYMENT
                              .installments,
                          "data-test": "pre-payment-installments-input",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.kindConfig(
                              "PRE_PAYMENT",
                              "installments",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t(
                          "planModal.kindConfigurations.RECURRENCY.amount"
                        ),
                        "label-for": "recurrency-amount-input",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        {
                          attrs: { prepend: _vm.$t("planModal.labels.money") },
                        },
                        [
                          _c("the-mask", {
                            staticClass: "form-control",
                            class: _vm.getValidationClass("kindConfigurations"),
                            attrs: {
                              id: "recurrency-amount-input",
                              disabled: _vm.readonly,
                              mask: [..._vm.maskThousand],
                              value:
                                _vm.formData.kindConfigurations.RECURRENCY
                                  .amount,
                              "data-test": "recurrency-amount-input",
                            },
                            on: {
                              input: function ($event) {
                                _vm.kindConfig(
                                  "RECURRENCY",
                                  "amount",
                                  parseInt($event)
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t(
                          "planModal.kindConfigurations.FIRST_PAYMENT.amount"
                        ),
                        "label-for": "first-payment-amount-input",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        {
                          attrs: { prepend: _vm.$t("planModal.labels.money") },
                        },
                        [
                          _c("the-mask", {
                            staticClass: "form-control",
                            class: _vm.getValidationClass("kindConfigurations"),
                            attrs: {
                              id: "first-payment-amount-input",
                              disabled: _vm.readonly,
                              mask: [..._vm.maskThousand],
                              value:
                                _vm.formData.kindConfigurations.FIRST_PAYMENT
                                  .amount,
                              "data-test": "first-payment-amount-input",
                            },
                            on: {
                              input: function ($event) {
                                _vm.kindConfig(
                                  "FIRST_PAYMENT",
                                  "amount",
                                  parseInt($event)
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t(
                          "planModal.kindConfigurations.SUBSCRIPTION_TERMINATION.amount"
                        ),
                        "label-for": "subscription-termination-amount-input",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        {
                          attrs: { prepend: _vm.$t("planModal.labels.money") },
                        },
                        [
                          _c("the-mask", {
                            staticClass: "form-control",
                            class: _vm.getValidationClass("kindConfigurations"),
                            attrs: {
                              id: "subscription-termination-amount-input",
                              disabled: _vm.readonly,
                              mask: [..._vm.maskThousand],
                              value:
                                _vm.formData.kindConfigurations
                                  .SUBSCRIPTION_TERMINATION.amount,
                              "data-test":
                                "subscription-termination-amount-input",
                            },
                            on: {
                              input: function ($event) {
                                _vm.kindConfig(
                                  "SUBSCRIPTION_TERMINATION",
                                  "amount",
                                  parseInt($event)
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }