var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.formData.modifiers
    ? _c(
        "div",
        [
          _c("b-dd-divider", { staticClass: "mb-4" }),
          _vm.formMode === "create"
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t(
                              "planModal.sections.planModifiers.label.activeModifiers"
                            ),
                          },
                        },
                        [
                          _c("b-form-checkbox-group", {
                            attrs: {
                              options: _vm.modifiersOptions,
                              name: "active-modifiers-input",
                            },
                            model: {
                              value: _vm.selectedModifiers,
                              callback: function ($$v) {
                                _vm.selectedModifiers = $$v
                              },
                              expression: "selectedModifiers",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            _vm.getModifiersComponents(),
            _vm._b(
              {
                tag: "component",
                on: {
                  change: function ($event) {
                    return _vm.dataInput("modifiers", $event)
                  },
                },
              },
              "component",
              _vm.$props,
              false
            )
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }