<template>
  <div v-if="formData.modifiers">
    <b-dd-divider class="mb-4" />

    <b-row v-if="formMode === 'create'">
      <b-col sm="12">
        <b-form-group
          :label="$t('planModal.sections.planModifiers.label.activeModifiers')"
        >
          <b-form-checkbox-group
            v-model="selectedModifiers"
            :options="modifiersOptions"
            name="active-modifiers-input"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <component
      v-bind="$props" :is="getModifiersComponents()"
      @change="dataInput('modifiers', $event)"
    />
  </div>
</template>

<script>
import { planInputsMixin } from '../planInputsMixin';
import PlanPpmModifier from './planPpmModifier'
import { checkModifiersValue } from '@components/modals/plan/commons/propType';

export default {
  name: 'PlanModifiers',
  mixins: [planInputsMixin],
  data() {
    return {
      activePpm: false,
      selectedModifiers: [],
      modifiersOptions: [
        {
          text: this.$t('planModal.sections.planModifiers.options.payPerMile'),
          value: 'PAY_PER_MILE'
        },
        {
          text: this.$t('planModal.sections.planModifiers.options.driverBehavior'),
          value: 'DRIVER_BEHAVIOR',
          disabled: true
        }
      ]
    };
  },
  methods: {
    getModifiersComponents() {
      return this.selectedModifiers.includes('PAY_PER_MILE')
      || checkModifiersValue(this.formData.modifiers)
        ? PlanPpmModifier
        : '';
    },
  },
}
</script>

